/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Message } from '../models/Message';
import type { MessageRequest } from '../models/MessageRequest';
import type { PaginatedMessageList } from '../models/PaginatedMessageList';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MessagesService {
    /**
     * List all messages, or create a new message.
     * @returns PaginatedMessageList
     * @throws ApiError
     */
    public static messagesList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedMessageList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/messages/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * List all messages, or create a new message.
     * @returns Message
     * @throws ApiError
     */
    public static messagesCreate({
        requestBody,
    }: {
        requestBody: MessageRequest,
    }): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/messages/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
