/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchedPurchaserRequest } from '../models/PatchedPurchaserRequest';
import type { Purchaser } from '../models/Purchaser';
import type { PurchaserRequest } from '../models/PurchaserRequest';
import type { PurchaserStatistics } from '../models/PurchaserStatistics';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PurchasersService {
    /**
     * API endpoint that allows purchasers to be viewed.
     * @returns Purchaser
     * @throws ApiError
     */
    public static purchasersList({
        ordering,
    }: {
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
    }): CancelablePromise<Array<Purchaser>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchasers/',
            query: {
                'ordering': ordering,
            },
        });
    }
    /**
     * API endpoint that allows purchasers to be viewed.
     * @returns Purchaser
     * @throws ApiError
     */
    public static purchasersCreate({
        requestBody,
    }: {
        requestBody: PurchaserRequest,
    }): CancelablePromise<Purchaser> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchasers/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * API endpoint that allows purchasers to be viewed.
     * @returns Purchaser
     * @throws ApiError
     */
    public static purchasersRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<Purchaser> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchasers/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * API endpoint that allows purchasers to be viewed.
     * @returns Purchaser
     * @throws ApiError
     */
    public static purchasersUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: PurchaserRequest,
    }): CancelablePromise<Purchaser> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/purchasers/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * API endpoint that allows purchasers to be viewed.
     * @returns Purchaser
     * @throws ApiError
     */
    public static purchasersPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedPurchaserRequest,
    }): CancelablePromise<Purchaser> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/purchasers/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * API endpoint that allows purchasers to be viewed.
     * @returns void
     * @throws ApiError
     */
    public static purchasersDestroy({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/purchasers/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * API endpoint that allows purchasers to be viewed.
     * @returns PurchaserStatistics
     * @throws ApiError
     */
    public static purchasersStatisticsList({
        rangeEnd,
        rangeStart,
        ordering,
    }: {
        rangeEnd: string,
        rangeStart: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
    }): CancelablePromise<Array<PurchaserStatistics>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchasers/statistics/{range_start}/{range_end}/',
            path: {
                'range_end': rangeEnd,
                'range_start': rangeStart,
            },
            query: {
                'ordering': ordering,
            },
        });
    }
}
