/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Unit } from '../models/Unit';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UnitsService {
    /**
     * API endpoint that allows drivers to be viewed.
     * @returns Unit
     * @throws ApiError
     */
    public static unitsList(): CancelablePromise<Array<Unit>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/units/',
        });
    }
    /**
     * API endpoint that allows drivers to be viewed.
     * @returns Unit
     * @throws ApiError
     */
    public static unitsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Unit.
         */
        id: number,
    }): CancelablePromise<Unit> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/units/{id}/',
            path: {
                'id': id,
            },
        });
    }
}
