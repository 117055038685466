/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssortmentPricing } from '../models/AssortmentPricing';
import type { AssortmentPricingRequest } from '../models/AssortmentPricingRequest';
import type { PatchedAssortmentPricingRequest } from '../models/PatchedAssortmentPricingRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AssortmentpricingService {
    /**
     * API endpoint that allows drivers to be viewed.
     * @returns AssortmentPricing
     * @throws ApiError
     */
    public static assortmentpricingList({
        ordering,
    }: {
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
    }): CancelablePromise<Array<AssortmentPricing>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/assortmentpricing/',
            query: {
                'ordering': ordering,
            },
        });
    }
    /**
     * API endpoint that allows drivers to be viewed.
     * @returns AssortmentPricing
     * @throws ApiError
     */
    public static assortmentpricingCreate({
        requestBody,
    }: {
        requestBody: AssortmentPricingRequest,
    }): CancelablePromise<AssortmentPricing> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/assortmentpricing/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * API endpoint that allows drivers to be viewed.
     * @returns AssortmentPricing
     * @throws ApiError
     */
    public static assortmentpricingRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<AssortmentPricing> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/assortmentpricing/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * API endpoint that allows drivers to be viewed.
     * @returns AssortmentPricing
     * @throws ApiError
     */
    public static assortmentpricingUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: AssortmentPricingRequest,
    }): CancelablePromise<AssortmentPricing> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/assortmentpricing/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * API endpoint that allows drivers to be viewed.
     * @returns AssortmentPricing
     * @throws ApiError
     */
    public static assortmentpricingPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedAssortmentPricingRequest,
    }): CancelablePromise<AssortmentPricing> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/assortmentpricing/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * API endpoint that allows drivers to be viewed.
     * @returns void
     * @throws ApiError
     */
    public static assortmentpricingDestroy({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/assortmentpricing/{id}/',
            path: {
                'id': id,
            },
        });
    }
}
