/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FinancialTransaction } from '../models/FinancialTransaction';
import type { PaginatedProjectList } from '../models/PaginatedProjectList';
import type { PatchedProjectRequest } from '../models/PatchedProjectRequest';
import type { Project } from '../models/Project';
import type { ProjectRequest } from '../models/ProjectRequest';
import type { WorkItem } from '../models/WorkItem';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProjectsService {
    /**
     * API endpoint that allows projects to be viewed.
     * @returns PaginatedProjectList
     * @throws ApiError
     */
    public static projectsList({
        name,
        ordering,
        page,
        pageSize,
        state,
        stock,
    }: {
        /**
         * Search by name
         */
        name?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * Filter by state
         *
         * * `0` - Quotation
         * * `100` - On Stock
         * * `200` - Operation
         * * `300` - Delivering
         * * `1000` - Finished
         * * `10000` - Lost
         */
        state?: Array<0 | 100 | 1000 | 10000 | 200 | 300>,
        stock?: boolean,
    }): CancelablePromise<PaginatedProjectList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/',
            query: {
                'name': name,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'state': state,
                'stock': stock,
            },
        });
    }
    /**
     * API endpoint that allows projects to be viewed.
     * @returns Project
     * @throws ApiError
     */
    public static projectsCreate({
        requestBody,
    }: {
        requestBody: ProjectRequest,
    }): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/projects/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * API endpoint that allows projects to be viewed.
     * @returns Project
     * @throws ApiError
     */
    public static projectsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Project.
         */
        id: number,
    }): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * API endpoint that allows projects to be viewed.
     * @returns Project
     * @throws ApiError
     */
    public static projectsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Project.
         */
        id: number,
        requestBody: ProjectRequest,
    }): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/projects/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * API endpoint that allows projects to be viewed.
     * @returns Project
     * @throws ApiError
     */
    public static projectsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Project.
         */
        id: number,
        requestBody?: PatchedProjectRequest,
    }): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/projects/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * API endpoint that allows projects to be viewed.
     * @returns void
     * @throws ApiError
     */
    public static projectsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Project.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/projects/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * API endpoint that allows projects to be viewed.
     * @returns binary
     * @throws ApiError
     */
    public static projectsDownloadAttachmentsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Project.
         */
        id: number,
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/{id}/downloadAttachments/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * API endpoint that allows projects to be viewed.
     * @returns FinancialTransaction
     * @throws ApiError
     */
    public static projectsFinancialTransactionsList({
        id,
        name,
        ordering,
        state,
    }: {
        /**
         * A unique integer value identifying this Project.
         */
        id: number,
        /**
         * Search by name
         */
        name?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * Filter by state
         *
         * * `0` - Quotation
         * * `100` - On Stock
         * * `200` - Operation
         * * `300` - Delivering
         * * `1000` - Finished
         * * `10000` - Lost
         */
        state?: Array<0 | 100 | 1000 | 10000 | 200 | 300>,
    }): CancelablePromise<Array<FinancialTransaction>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/{id}/financialTransactions/',
            path: {
                'id': id,
            },
            query: {
                'name': name,
                'ordering': ordering,
                'state': state,
            },
        });
    }
    /**
     * API endpoint that allows projects to be viewed.
     * @returns WorkItem
     * @throws ApiError
     */
    public static projectsWorkList({
        id,
        name,
        ordering,
        state,
    }: {
        /**
         * A unique integer value identifying this Project.
         */
        id: number,
        /**
         * Search by name
         */
        name?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * Filter by state
         *
         * * `0` - Quotation
         * * `100` - On Stock
         * * `200` - Operation
         * * `300` - Delivering
         * * `1000` - Finished
         * * `10000` - Lost
         */
        state?: Array<0 | 100 | 1000 | 10000 | 200 | 300>,
    }): CancelablePromise<Array<WorkItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/{id}/work/',
            path: {
                'id': id,
            },
            query: {
                'name': name,
                'ordering': ordering,
                'state': state,
            },
        });
    }
}
