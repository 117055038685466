/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchedUserAndPermissionsRequest } from '../models/PatchedUserAndPermissionsRequest';
import type { UserAndPermissions } from '../models/UserAndPermissions';
import type { UserAndPermissionsRequest } from '../models/UserAndPermissionsRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersService {
    /**
     * API endpoint that allows users to be viewed or edited.
     * @returns UserAndPermissions
     * @throws ApiError
     */
    public static usersList({
        ordering,
    }: {
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
    }): CancelablePromise<Array<UserAndPermissions>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/',
            query: {
                'ordering': ordering,
            },
        });
    }
    /**
     * API endpoint that allows users to be viewed or edited.
     * @returns UserAndPermissions
     * @throws ApiError
     */
    public static usersCreate({
        requestBody,
    }: {
        requestBody: UserAndPermissionsRequest,
    }): CancelablePromise<UserAndPermissions> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * API endpoint that allows users to be viewed or edited.
     * @returns UserAndPermissions
     * @throws ApiError
     */
    public static usersRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this user.
         */
        id: number,
    }): CancelablePromise<UserAndPermissions> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * API endpoint that allows users to be viewed or edited.
     * @returns UserAndPermissions
     * @throws ApiError
     */
    public static usersUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this user.
         */
        id: number,
        requestBody: UserAndPermissionsRequest,
    }): CancelablePromise<UserAndPermissions> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * API endpoint that allows users to be viewed or edited.
     * @returns UserAndPermissions
     * @throws ApiError
     */
    public static usersPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this user.
         */
        id: number,
        requestBody?: PatchedUserAndPermissionsRequest,
    }): CancelablePromise<UserAndPermissions> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/users/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * API endpoint that allows users to be viewed or edited.
     * @returns void
     * @throws ApiError
     */
    public static usersDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this user.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * API endpoint that allows users to be viewed or edited.
     * @returns UserAndPermissions
     * @throws ApiError
     */
    public static usersMeRetrieve(): CancelablePromise<UserAndPermissions> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/me/',
        });
    }
}
